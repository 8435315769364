<div class="strategy-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="strategy-image">
                    <img src="assets/img/aiittiva2.jpg" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="strategy-content ptb-70">
                    <div class="section-title">
                        <h4>Soluciones basadas en Inteligencia Artificial</h4>
                        <h2>Nuestra <span>Estrategia</span></h2>
                        <p>En ITTIVA, hemos adoptado la inteligencia artificial (IA) como un componente clave en nuestras soluciones tecnológicas, buscando siempre estar a la vanguardia de la innovación. La IA nos permite ofrecer productos más inteligentes y eficientes, adaptados a las necesidades específicas de nuestros clientes y capaces de mejorar significativamente sus operaciones. </p>
                    </div>
                    <ul class="features-list">
                        <li><i class="fa fa-check"></i>Automatización y Optimización de Procesos</li>
                        <li><i class="fa fa-check"></i>Análisis Predictivo y de Datos</li>
                        <li><i class="fa fa-check"></i>Personalización a Escala</li>
                        <li><i class="fa fa-check"></i>Mejoras Continuas mediante Aprendizaje Automático</li>
                        <li><i class="fa fa-check"></i>Ética y Seguridad en IA</li>
                    </ul>
                    <p>La adopción de la inteligencia artificial en ITTIVA no es solo una mejora tecnológica, sino una redefinición de cómo vemos y solucionamos los problemas de nuestros clientes.</p>
                </div>
            </div>
        </div>
    </div>
    <div class="shape1"><img src="assets/img/shape1.png" alt="image"></div>
</div>
