<div class="why-we-different ptb-100 bg-F7F5F4">
    <div class="container">
        <div class="section-title">
            <h2>¿Que nos hace <span>DIFERENTES</span>?</h2>
            <p>En ITTIVA entendemos que en el dinámico mundo del desarrollo y consultoría de software, la excelencia no solo se mide por los productos entregados, sino también por el camino que recorremos junto a nuestros clientes para alcanzar sus metas.</p>
        </div>
        <div class="tabset why-we-different-tabset">
            <!-- Tab 1 -->
            <input type="radio" name="tabset" id="tab1" aria-controls="speed-flexibility" checked>
            <label for="tab1">Flexibilidad y Rapidez</label>
            <!-- Tab 2 -->
            <input type="radio" name="tabset" id="tab2" aria-controls="profeesional-work">
            <label for="tab2">Trabajo Profesional</label>
            <!-- Tab 3 -->
            <input type="radio" name="tabset" id="tab3" aria-controls="experienced-staff">
            <label for="tab3">Experiencia</label>
            <!-- Tab 4 -->
            <input type="radio" name="tabset" id="tab4" aria-controls="custom-support">
            <label for="tab4">Seguridad</label>
            <div class="tab-panels">
                <div id="speed-flexibility" class="tab-panel">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-img">
                                <img src="assets/img/agile_itti.png" alt="img">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-content">
                                <h3>Flexibilidad y Rapidez</h3>
                                <p>Entendemos que las necesidades del mercado pueden cambiar rápidamente. En ITTIVA, nos adaptamos a los cambios y requerimientos de nuestros clientes con agilidad y eficiencia. Nuestro enfoque modular en el desarrollo permite implementaciones rápidas y eficaces, asegurando que nuestros clientes puedan aprovechar las oportunidades del mercado sin demoras..</p>
                                <ul>
                                    <li><i class="fas fa-check"></i> Diseño Creativo</li>
                                    <li><i class="fas fa-check"></i> La mejores Practicas de desarrollo</li>
                                    <li><i class="fas fa-check"></i> Integración continua con el cliente</li>
                                    <li><i class="fas fa-check"></i> Escuchamos y entendemos las necesidades del cliente.</li>
                                    <li><i class="fas fa-check"></i> Desarrollos sustentables</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="profeesional-work" class="tab-panel">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-img">
                                <img src="assets/img/exp_itti.png" alt="img">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-content">
                                <h3>Trabajo Profesional</h3>
                                <p>Cada proyecto en ITTIVA es manejado con un estándar alto de profesionalismo. Nuestro equipo no solo cuenta con las certificaciones y formación técnica de vanguardia, sino que también adopta las mejores prácticas de la industria para garantizar la entrega de soluciones que no solo cumplen sino superan las expectativas de calidad y rendimiento.</p>
                                <ul>
                                    <li><i class="fas fa-check"></i> Certificacion ISO 27001</li>
                                    <li><i class="fas fa-check"></i> La mejores practicas de desarrollo</li>
                                    <li><i class="fas fa-check"></i> Alto desempeño</li>
                                    <li><i class="fas fa-check"></i> Personal altamente capacitado</li>
                                    <li><i class="fas fa-check"></i> Código documentado</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="experienced-staff" class="tab-panel">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-img">
                                <img src="assets/img/exp_itti.png" alt="img">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-content">
                                <h3>Experiencia</h3>
                                <p>Con años de operación en el sector de tecnología, ITTIVA se enorgullece de su vasta experiencia trabajando con una amplia gama de industrias. Esta experiencia nos brinda una perspectiva única y un profundo entendimiento de las especificidades y desafíos que enfrentan nuestros clientes, lo que nos permite ofrecer soluciones precisamente ajustadas a sus necesidades</p>
                                <ul>
                                    <li><i class="fas fa-check"></i> + 7 Años de experiencia.</li>
                                    <li><i class="fas fa-check"></i> + 45 clientes satisfechos.</li>
                                    <li><i class="fas fa-check"></i> + 250,000 Horas de trabajo.</li>
                                    <li><i class="fas fa-check"></i> + 50 profesionales colaborando con nosotros.</li>
                                    <li><i class="fas fa-check"></i> + 98 Proyecto concretados.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="custom-support" class="tab-panel">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-img">
                                <img src="assets/img/seguri_itti.png" alt="img">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-content">
                                <h3>Seguridad</h3>
                                <p>En ITTIVA, la seguridad no es una opción, sino una promesa. Incorporamos principios de seguridad desde el diseño inicial hasta la implementación y más allá, asegurando que todos los proyectos no solo cumplan con los estándares de seguridad más exigentes, sino que también mantengan la integridad y confidencialidad de los datos del cliente en todo momento.</p>
                                <ul>
                                    <li><i class="fas fa-check"></i> Escaneo Sonar y covergae a + del 90%</li>
                                    <li><i class="fas fa-check"></i> Escaneos de Veracode y vulnerabilidades al 100%</li>
                                    <li><i class="fas fa-check"></i> Pruebas de penetración sin incidentes.</li>
                                    <li><i class="fas fa-check"></i> Desarrollos a prueba de injeccioón SQL</li>
                                    <li><i class="fas fa-check"></i> Implementaciones con SAML y otros sistemas de logueo seguro.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
