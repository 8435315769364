<div class="cta-area ptb-100">
    <div class="container">
        <div class="cta-content">
            <div class="section-title mb-0">
                <h4>En ITTIVA, la innovación no es solo una opción; es nuestro fundamento. Creamos soluciones de software a medida que transforman negocios.</h4>
                <h2>Con un enfoque incansable en la calidad, garantizamos que cada línea de código cumpla y supere las expectativas</h2>
                <p>Utilizando lo último en Big Data e inteligencia artificial, ITTIVA adelanta a su empresa hacia el futuro.</p>
                <a (click)="onClick('contact')" class="btn btn-primary">Contactanos</a>
                <a (click)="onClick('work')" class="btn btn-primary view-work">Nuestro Trabajo</a>
            </div>
        </div>
    </div>
</div>
