<div id="pricing" class="pricing-area pt-100 pb-70 bg-F7F5F4">
    <div class="container">
        <div class="section-title">
            <h2><span>Servicios</span></h2>
            <p>Presentamos algunos de nuestros servicios mas solicitados.</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="pricingTable">
                    <h3 class="title">Consultoria y desarollo</h3>
                    <div class="price-value">
  <!--                       <span class="currency">$</span>
                        <span class="amount">39.00</span>
                        <span class="month">/x Hora</span> -->
                    </div>
                    <ul class="pricing-content">
                        <li><i class="fas fa-check"></i> Soluciones personalizadas</li>
                        <li><i class="fas fa-check"></i> Acceso a expertise especializado</li>
                        <li><i class="fas fa-check"></i> Sistema bajo estandares de Seguridad</li>
                        <li><i class="fas fa-check"></i> Escalabilidad</li>
                        <li><i class="fas fa-check"></i> Soporte y mantenimiento continuos</li>
                        <li><i class="fas fa-check"></i> Reducción de costos a largo plazo</li>
                        <li><i class="fas fa-check"></i> Pruebas Verificadas</li>
                    </ul>
                    <a routerLink="/" class="btn btn-primary">Cotice un servicio.</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="pricingTable">
                    <h3 class="title">Analitica de Datos</h3>
                    <div class="price-value">
      <!--                   <span class="currency">$</span>
                        <span class="amount">49.00</span>
                        <span class="month">/x Hora</span> -->
                    </div>
                    <ul class="pricing-content">
                        <li><i class="fas fa-check"></i> Integración de Datos</li>
                        <li><i class="fas fa-check"></i> Inteligencia de Negocios (BI)</li>
                        <li><i class="fas fa-check"></i> Visualización de Datos, Reportes.</li>
                        <li><i class="fas fa-check"></i> Análisis Predictivo</li>
                        <li><i class="fas fa-check"></i> Minería de Datos</li>
                        <li><i class="fas fa-check"></i> Limpieza y Preparación de Datos</li>
                        <li><i class="fas fa-check"></i> Consultoría en Big Data</li>


                    </ul>
                    <a routerLink="/" class="btn btn-primary">Cotice un servicio.</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="pricingTable">
                    <h3 class="title">COBOL - IBM 390</h3>
                    <div class="price-value">
                 <!--        <span class="currency">$</span>
                        <span class="amount">49.00</span>
                        <span class="month">/x Hora</span> -->
                    </div>
                    <ul class="pricing-content">
                        <li><i class="fas fa-check"></i> Mantenimiento y Optimización de Aplicaciones</li>
                        <li><i class="fas fa-check"></i> Modernización de Aplicaciones</li>
                        <li><i class="fas fa-check"></i> Migración de Sistemas</li>
                        <li><i class="fas fa-check"></i> Testing y Calidad de Software</li>
                        <li><i class="fas fa-check"></i> Soporte de Emergencia y Resolución de Problemas</li>
                        <li><i class="fas fa-check"></i> Capacitación y Soporte Técnico</li>
                        <li><i class="fas fa-check"></i> Consultoría Estratégica</li>
                    </ul>
                    <a routerLink="/" class="btn btn-primary">Cotice un servicio.</a>
                </div>
            </div>
        </div>
    </div>
    <div class="shape1"><img src="assets/img/shape1.png" alt="image"></div>
</div>
