<footer class="footer-area">
    <div class="container">
        <img   class="logo" src="assets/img/logo_b.svg" alt="img">


        <ul>
            <li><a href="#" target="_blank" class="fab fa-facebook-f"></a></li>
            <li><a href="#" target="_blank" class="fab fa-twitter"></a></li>
            <li><a href="#" target="_blank" class="fab fa-linkedin-in"></a></li>
            <li><a href="#" target="_blank" class="fab fa-instagram"></a></li>
            <li><a href="#" target="_blank" class="fab fa-skype"></a></li>
        </ul>
        <p>Consulta nuestro aviso de <a href="https://ittiva.com/assets/AVISO INTEGRAL DE PRIVACIDAD ITTIVA.docx" download>PRIVACIDAD.</a>  </p>
        <p>  ©Powered By <a href="https://ittiva.com/" target="_blank">Ittiva</a> </p>
    </div>
</footer>
<!--
<app-demo-sidebar></app-demo-sidebar> -->

<ngx-scrolltop></ngx-scrolltop>
