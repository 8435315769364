<div id="about" class="about-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Sobre <span>Ittiva</span></h2>
            <p>Somos un equipo de profesionales apasionados y comprometidos con la excelencia en el campo de la tecnología de la información. En ITTIVA, valoramos la creatividad, la innovación y la ética en todos nuestros proyectos y relaciones. Nos destacamos por nuestra capacidad para entender en profundidad las necesidades de nuestros clientes y convertirlas en soluciones efectivas y eficientes.</p>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <div class="section-title">
                        <h2>Estrategias de <span>DESARROLLO AGIL </span></h2>
                        <p>abordamos cada proyecto con una estrategia ágil que se centra en la colaboración, la adaptabilidad y la entrega continua. Nuestro enfoque ágil nos permite responder de manera efectiva a la dinámica del mercado y a las necesidades cambiantes de nuestros clientes durante el ciclo de vida del desarrollo.</p>
                    </div>
                    <ul class="features-list">
                        <li><i class="fas fa-check"></i>Planificación Iterativa</li>
                        <li><i class="fas fa-check"></i>Desarrollo Incremental</li>
                        <li><i class="fas fa-check"></i>Comunicación Constante</li>
                        <li><i class="fas fa-check"></i>Evaluación y Adaptación</li>
                        <li><i class="fas fa-check"></i>Calidad de Codigo</li>
                        <li><i class="fas fa-check"></i>Satisfacción al cliente</li>
                    </ul>
                    <p>La adopción de metodologías ágiles en ITTIVA nos permite no solo enfrentar los desafíos del desarrollo de software sino también transformar estos desafíos en oportunidades para la innovación y el éxito del cliente.</p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-video">
                    <img src="assets/img/agileittiva.jpg" alt="about">
                    <div class="video-btn">
                        <!-- <button class="popup-youtube" (click)="ngxSmartModalService.getModal('popupOne').open()"><i class="fas fa-play"></i></button> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="video-popup">
    <!-- <ngx-smart-modal #popupOne [identifier]="'popupOne'">
        <iframe src="https://www.youtube.com/embed/WQ119jAN4Xo" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </ngx-smart-modal> -->
</div>
