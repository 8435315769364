<div id="work" class="work-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Nuestras <span>Tecnologías</span></h2>
            <p>Trabajamos para estar actualizados en los frameworks mas actuales, robustos y seguros del mercado, pero a su vez trabajamos con una variedad de lenguajes que nos permiten adaptarnos y atender cualquier necesidad con nuestros clientes.</p>
        </div>
    </div>
    <div class="container-fluid">
        <div class="work-tabs">
            <ul class="nav-tabset">
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab1'}">
                    <span (click)="switchTab($event, 'tab1')">
                        Relevantes
                    </span>
                </li>
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab2'}">
                    <span (click)="switchTab($event, 'tab2')">
                        Front
                    </span>
                </li>
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab3'}">
                    <span (click)="switchTab($event, 'tab3')">
                        Plataformas de Aplicaciones
                    </span>
                </li>
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab4'}">
                    <span (click)="switchTab($event, 'tab4')">
                       Manejo de Datos
                    </span>
                </li>

            </ul>
            <div class="tabs-container">
                <div class="pane" id="tab1" *ngIf="currentTab === 'tab1'">
                    <div class="row">
                        <div class="col-lg-2 ">
                            <div class="single-work">
                                <img src="assets/img/Angular.png" alt="work-img">
                                <div class="work-content">
                                    <h3>Contactanos...</h3>
                                    <ul>
                                        <li><a routerLink="/">Calidad</a></li>
                                        <li> . </li>
                                        <li><a routerLink="/">Seguridad</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/java.png" alt="work-img">
                                <div class="work-content">
                                    <h3>Contactanos...</h3>
                                    <ul>
                                        <li><a routerLink="/">Calidad</a></li>
                                        <li> . </li>
                                        <li><a routerLink="/">Seguridad</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/cobol.png" alt="work-img">
                                <div class="work-content">
                                    <h3>Contactanos...</h3>
                                    <ul>
                                        <li><a routerLink="/">Calidad</a></li>
                                        <li>.</li>
                                        <li><a routerLink="/">Seguridad</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/ipc.png" alt="work-img">
                                <div class="work-content">
                                    <h3>Contactanos...</h3>
                                    <ul>
                                        <li><a routerLink="/">Calidad</a></li>
                                        <li>.</li>
                                        <li><a routerLink="/">Seguridad</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/c.png" alt="work-img">
                                <div class="work-content">
                                    <h3>Contactanos...</h3>
                                    <ul>
                                        <li><a routerLink="/">Calidad</a></li>
                                        <li>.</li>
                                        <li><a routerLink="/">Seguridad</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/springb.png" alt="work-img">
                                <div class="work-content">
                                    <h3>Contactanos...</h3>
                                    <ul>
                                        <li><a routerLink="/">Calidad</a></li>
                                        <li>.</li>
                                        <li><a routerLink="/">Seguridad</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/googleana.jpg" alt="work-img">
                                <div class="work-content">
                                    <h3>Contactanos...</h3>
                                    <ul>
                                        <li><a routerLink="/">Calidad</a></li>
                                        <li>.</li>
                                        <li><a routerLink="/">Seguridad</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/aws.png" alt="work-img">
                                <div class="work-content">
                                    <h3>Contactanos...</h3>
                                    <ul>
                                        <li><a routerLink="/">Calidad</a></li>
                                        <li>.</li>
                                        <li><a routerLink="/">Seguridad</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/db2.png" alt="work-img">
                                <div class="work-content">
                                    <h3>Contactanos...</h3>
                                    <ul>
                                        <li><a routerLink="/">Calidad</a></li>
                                        <li>.</li>
                                        <li><a routerLink="/">Seguridad</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/cloudera.png" alt="work-img">
                                <div class="work-content">
                                    <h3>Contactanos...</h3>
                                    <ul>
                                        <li><a routerLink="/">Calidad</a></li>
                                        <li> . </li>
                                        <li><a routerLink="/">Seguridad</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/azure.png" alt="work-img">
                                <div class="work-content">
                                    <h3>Contactanos...</h3>
                                    <ul>
                                        <li><a routerLink="/">Calidad</a></li>
                                        <li> . </li>
                                        <li><a routerLink="/">Seguridad</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>








                <div class="pane" id="tab2" *ngIf="currentTab === 'tab2'">
                    <div class="row">
                        <div class="col-lg-2 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/react.png" alt="work-img">
                                <div class="work-content">
                                    <h3>Contactanos...</h3>
                                    <ul>
                                        <li><a routerLink="/">Calidad</a></li>
                                        <li> . </li>
                                        <li><a routerLink="/">Seguridad</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/Angular.png" alt="work-img">
                                <div class="work-content">
                                    <h3>Contactanos...</h3>
                                    <ul>
                                        <li><a routerLink="/">Calidad</a></li>
                                        <li>.</li>
                                        <li><a routerLink="/">Seguridad</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/vue.png" alt="work-img">
                                <div class="work-content">
                                    <h3>Contactanos...</h3>
                                    <ul>
                                        <li><a routerLink="/">Calidad</a></li>
                                        <li>.</li>
                                        <li><a routerLink="/">Seguridad</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/jquery.png" alt="work-img">
                                <div class="work-content">
                                    <h3>Contactanos...</h3>
                                    <ul>
                                        <li><a routerLink="/">Calidad</a></li>
                                        <li>.</li>
                                        <li><a routerLink="/">Seguridad</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/css.png" alt="work-img">
                                <div class="work-content">
                                    <h3>Contactanos...</h3>
                                    <ul>
                                        <li><a routerLink="/">Calidad</a></li>
                                        <li>.</li>
                                        <li><a routerLink="/">Seguridad</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/javascript.png" alt="work-img">
                                <div class="work-content">
                                    <h3>Contactanos...</h3>
                                    <ul>
                                        <li><a routerLink="/">Calidad</a></li>
                                        <li>.</li>
                                        <li><a routerLink="/">Seguridad</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/node.png" alt="work-img">
                                <div class="work-content">
                                    <h3>Contactanos...</h3>
                                    <ul>
                                        <li><a routerLink="/">Calidad</a></li>
                                        <li>.</li>
                                        <li><a routerLink="/">Seguridad</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/html.png" alt="work-img">
                                <div class="work-content">
                                    <h3>Contactanos...</h3>
                                    <ul>
                                        <li><a routerLink="/">Calidad</a></li>
                                        <li>.</li>
                                        <li><a routerLink="/">Seguridad</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/ts.png" alt="work-img">
                                <div class="work-content">
                                    <h3>Contactanos...</h3>
                                    <ul>
                                        <li><a routerLink="/">Calidad</a></li>
                                        <li>.</li>
                                        <li><a routerLink="/">Seguridad</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pane" id="tab3" *ngIf="currentTab === 'tab3'">
                    <div class="row">
                        <div class="col-lg-2 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/openshift.png" alt="work-img">
                                <div class="work-content">
                                    <h3>Contactanos...</h3>
                                    <ul>
                                        <li><a routerLink="/">Calidad</a></li>
                                        <li> . </li>
                                        <li><a routerLink="/">Seguridad</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/jboss.png" alt="work-img">
                                <div class="work-content">
                                    <h3>Contactanos...</h3>
                                    <ul>
                                        <li><a routerLink="/">Calidad</a></li>
                                        <li> . </li>
                                        <li><a routerLink="/">Seguridad</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/was.jpg" alt="work-img">
                                <div class="work-content">
                                    <h3>Contactanos...</h3>
                                    <ul>
                                        <li><a routerLink="/">Calidad</a></li>
                                        <li> . </li>
                                        <li><a routerLink="/">Seguridad</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/tomcat.jpg" alt="work-img">
                                <div class="work-content">
                                    <h3>Contactanos...</h3>
                                    <ul>
                                        <li><a routerLink="/">Calidad</a></li>
                                        <li> . </li>
                                        <li><a routerLink="/">Seguridad</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/nginx.png" alt="work-img">
                                <div class="work-content">
                                    <h3>Games Calidad</h3>
                                    <ul>
                                        <li><a href="#">IT</a></li>
                                        <li> . </li>
                                        <li><a href="#">Games</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/wildfly.png" alt="work-img">
                                <div class="work-content">
                                    <h3>Contactanos...</h3>
                                    <ul>
                                        <li><a routerLink="/">Calidad</a></li>
                                        <li> . </li>
                                        <li><a routerLink="/">Seguridad</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/kuber.png" alt="work-img">
                                <div class="work-content">
                                    <h3>Contactanos...</h3>
                                    <ul>
                                        <li><a routerLink="/">Calidad</a></li>
                                        <li> . </li>
                                        <li><a routerLink="/">Seguridad</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/azure.png" alt="work-img">
                                <div class="work-content">
                                    <h3>Contactanos...</h3>
                                    <ul>
                                        <li><a routerLink="/">Calidad</a></li>
                                        <li> . </li>
                                        <li><a routerLink="/">Seguridad</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/docker.png" alt="work-img">
                                <div class="work-content">
                                    <h3>Contactanos...</h3>
                                    <ul>
                                        <li><a routerLink="/">Calidad</a></li>
                                        <li> . </li>
                                        <li><a routerLink="/">Seguridad</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pane" id="tab4" *ngIf="currentTab === 'tab4'">
                    <div class="row">
                        <div class="col-lg-2 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/cloudera.png" alt="work-img">
                                <div class="work-content">
                                    <h3>Contactanos...</h3>
                                    <ul>
                                        <li><a routerLink="/">Calidad</a></li>
                                        <li> . </li>
                                        <li><a routerLink="/">Seguridad</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/spark.jpg" alt="work-img">
                                <div class="work-content">
                                    <h3>Contactanos...</h3>
                                    <ul>
                                        <li><a routerLink="/">Calidad</a></li>
                                        <li>.</li>
                                        <li><a routerLink="/">Seguridad</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/oracle.png" alt="work-img">
                                <div class="work-content">
                                    <h3>Contactanos...</h3>
                                    <ul>
                                        <li><a routerLink="/">Calidad</a></li>
                                        <li>.</li>
                                        <li><a routerLink="/">Seguridad</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/mongo.png" alt="work-img">
                                <div class="work-content">
                                    <h3>Contactanos...</h3>
                                    <ul>
                                        <li><a routerLink="/">Calidad</a></li>
                                        <li>.</li>
                                        <li><a routerLink="/">Seguridad</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/postgre.png" alt="work-img">
                                <div class="work-content">
                                    <h3>Contactanos...</h3>
                                    <ul>
                                        <li><a routerLink="/">Calidad</a></li>
                                        <li>.</li>
                                        <li><a routerLink="/">Seguridad</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/sqlserver.png" alt="work-img">
                                <div class="work-content">
                                    <h3>Contactanos...</h3>
                                    <ul>
                                        <li><a routerLink="/">Calidad</a></li>
                                        <li>.</li>
                                        <li><a routerLink="/">Seguridad</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/db2.png" alt="work-img">
                                <div class="work-content">
                                    <h3>Contactanos...</h3>
                                    <ul>
                                        <li><a routerLink="/">Calidad</a></li>
                                        <li>.</li>
                                        <li><a routerLink="/">Seguridad</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/tableau.png" alt="work-img">
                                <div class="work-content">
                                    <h3>Contactanos...</h3>
                                    <ul>
                                        <li><a routerLink="/">Calidad</a></li>
                                        <li>.</li>
                                        <li><a routerLink="/">Seguridad</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/googleana.jpg" alt="work-img">
                                <div class="work-content">
                                    <h3>Contactanos...</h3>
                                    <ul>
                                        <li><a routerLink="/">Calidad</a></li>
                                        <li>.</li>
                                        <li><a routerLink="/">Seguridad</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pane" id="tab5" *ngIf="currentTab === 'tab5'">
                    <div class="row">
                        <div class="col-lg-2 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/Angular.png" alt="work-img">
                                <div class="work-content">
                                    <h3>Contactanos...</h3>
                                    <ul>
                                        <li><a routerLink="/">Calidad</a></li>
                                        <li>.</li>
                                        <li><a routerLink="/">Seguridad</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/Angular.png" alt="work-img">
                                <div class="work-content">
                                    <h3>Contactanos...</h3>
                                    <ul>
                                        <li><a routerLink="/">Calidad</a></li>
                                        <li>.</li>
                                        <li><a routerLink="/">Seguridad</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pane" id="tab6" *ngIf="currentTab === 'tab6'">
                    <div class="row">
                        <div class="col-lg-2 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/Angular.png" alt="work-img">
                                <div class="work-content">
                                    <h3>Contactanos...</h3>
                                    <ul>
                                        <li><a routerLink="/">Calidad</a></li>
                                        <li>.</li>
                                        <li><a routerLink="/">Seguridad</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/Angular.png" alt="work-img">
                                <div class="work-content">
                                    <h3>Contactanos...</h3>
                                    <ul>
                                        <li><a routerLink="/">Calidad</a></li>
                                        <li>.</li>
                                        <li><a routerLink="/">Seguridad</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
