<div id="welcome" class="welcome-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h4>Siendo creativos de la mano de la TECNOLOGIA</h4>
            <h2>Bienvenido a <span>ITTIVA</span></h2>
            <p>Somos un equipo de profesionales apasionados y comprometidos con la excelencia en el campo de las tecnologías de la información</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="far fa-edit"></i>
                    </div>
                    <h3>Diseño Creativo</h3>
                    <p>En ITTIVA, el diseño creativo es un pilar fundamental en nuestros desarrollos, y lo abordamos con una estrategia que enfatiza la innovación y la funcionalidad centrada en el usuario. </p>
                    <a (click)="onClick('about')" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-cog"></i>
                    </div>
                    <h3>Inteligencia Artificial</h3>
                    <p>En ITTIVA, hemos adoptado la inteligencia artificial (IA) como un componente clave en nuestras soluciones tecnológicas, buscando siempre estar a la vanguardia de la innovación.</p>
                    <a (click)="onClick('strategy')" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="far fa-question"></i>
                    </div>
                    <h3>¿Quienes Somos?</h3>
                    <p>Un rapido vistazo a nuestra misión y visión como empresa que trabaja a favor de la automatización e implementación de las nuevas tecnologias</p>
                    <a (click)="onClick('about')" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                </div>
            </div>
        </div>
    </div>
    <div class="shape2" ><img src="assets/img/shape1.png" alt="image"></div>
</div>
