<div class="who-we-are-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Nuestras  <span>Fortalezas</span></h2>
            <p>Con un enfoque centrado en la implementación de tecnologías avanzadas y sostenibles, ITTIVA se esfuerza por transformar los procesos empresariales de sus clientes, optimizando su eficiencia y competitividad en el mercado global.</p>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fas fa-database"></i>
                    <h3>Big Data</h3>
                    <p>Especializados en soluciones de Big Data que permiten a las empresas transformar grandes volúmenes de datos desestructurados en insights accionables y estratégicos. Con una profunda experiencia en tecnologías como Hadoop, Spark y plataformas de almacenamiento en la nube</p>
                    <span>1</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fa fa-code"></i>
                    <h3>Desarrollo Web a Medida</h3>
                    <p>Creamos soluciones web personalizadas que están perfectamente alineadas con las necesidades específicas del cliente. Desde sitios web corporativos hasta complejas aplicaciones web, nuestro enfoque se centra en la usabilidad, el diseño responsive y la optimización del rendimiento.</p>
                    <span>2</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fa fa-arrows-alt"></i>
                    <h3>Consultoria TI</h3>
                    <p>Contamos con personal especializado en las diferentes ramas de TI, con el objetivo de ser socios estratégicos para la atención de sus necesidades, tecnologías propias de la empresa y de sus clientes.</p>
                    <span>3</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="far fa-hand-scissors"></i>
                    <h3>Telecomunicaciones</h3>
                    <p>Implementamos soluciones sobre telecomunicaciones como cableado estructurado, voz y datos, video vigilancia, entre otras. Centralización de la información en una fuente única mejorando la comunicación..</p>
                    <span>4</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fa fa-mobile"></i>
                    <h3>Aplicaciones Móviles</h3>
                    <p>ITTIVA es un líder en el desarrollo de aplicaciones móviles, ofreciendo tanto aplicaciones nativas como soluciones cross-platform. Dominamos las plataformas iOS y Android, empleando frameworks avanzados como Flutter y React Native para entregar aplicaciones que no solo lucen espectaculares sino que también ofrecen una experiencia de usuario fluida y envolvente. </p>
                    <span>5</span>
                </div>
            </div>
           <!--  <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="far fa-life-ring"></i>
                    <h3>We are support</h3>
                    <p>Fringilla augue at maximus vest vestibulum. Nam pulvinar vitaessst neque et porttitor. Praesent sed nis.</p>
                    <span>6</span>
                </div>
            </div> -->
        </div>
    </div>
</div>
