<div class="faq-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Preguntas <span>Frecuentes</span></h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div class="faq-accordion">
                    <div class="accordion">
                        <div class="accordion-section">
                            <div class="accordion-header" (click)="toggleSection(0)" [ngClass]="{ 'open': isSectionOpen(0) }">
                                ¿Como pueden garantisar la seguridad de mi información al trabajar con ustedes?
                            </div>
                            <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(0) }">
                                <p>En ITTIVA, nos comprometemos constantemente a mejorar la calidad y seguridad de nuestros servicios y procesos. Es por esta razón que estamos emocionados de compartir con nuestros clientes y colaboradores que hemos logrado la certificación ISO 27001, el estándar internacional para la gestión de la seguridad de la información (SGSI).

                                    Esta certificación es testimonio de nuestro compromiso con la seguridad y la protección de los datos confiados a nuestra empresa. ISO 27001 nos proporciona un marco de trabajo que garantiza la adopción de un sistema de gestión de la seguridad de la información que incluye procesos, tecnología y personal, todo diseñado para proteger nuestros activos de información.</p>
                            </div>
                        </div>
                        <div class="accordion-section">
                            <div class="accordion-header" (click)="toggleSection(1)" [ngClass]="{ 'open': isSectionOpen(1) }">
                                ¿Realizan trabajos en sitio o solamente via remota?
                            </div>
                            <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(1) }">
                                <p>En ITTIVA, entendemos que cada cliente tiene necesidades únicas y que la flexibilidad es clave para proporcionar soluciones efectivas y eficientes. Por eso, estamos orgullosos de ofrecer una modalidad de servicio dual: podemos trabajar directamente en tus instalaciones o proporcionar asistencia de manera remota, según lo que mejor se adapte a tus necesidades y preferencias.</p>
                            </div>
                        </div>
                        <div class="accordion-section">
                            <div class="accordion-header" (click)="toggleSection(2)" [ngClass]="{ 'open': isSectionOpen(2) }">
                                ¿Como puedo contactarlos?
                            </div>
                            <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(2) }">
                                <p>Tenemos vcarias lineas de contacto, que pueden ser a traves de los formularios de esta pagina o a travez de nuestras redes sociales, incluso en nuestras oficinas fisicas.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div class="col-lg-5 col-md-12">
                <div class="faq-image">
                    <img src="assets/img/faq_itti.png" alt="img">
                </div>
            </div>
        </div>
    </div>
</div>
