<div class="main-banner item-bg-one">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-text">
                    <h4>Descubre el poder de la tecnología</h4>
                    <h1>Con <span style="color: #FFAA3B;">ITTIVA</span> conoce el poder de la Tecnología</h1>
                    <p>no solo construimos sitios web; creamos las plataformas sobre las cuales crecerá tu negocio. Unete a nosotros para navegar el futuro del comercio digital con confianza y creatividad.</p>
                    <a (click)="onClick('welcome')" class="btn btn-primary">Inicio</a>
                    <a (click)="onClick('work')" class="btn btn-primary view-work">Nuestro Trabajo</a>
                </div>
            </div>
        </div>
    </div>
</div>

<app-welcome></app-welcome>

<app-about></app-about>

<app-strategy></app-strategy>

<app-who-we-are></app-who-we-are>

<app-cta></app-cta>

<app-skill></app-skill>

<app-funfacts></app-funfacts>

<!-- <app-team></app-team> -->

<!-- <app-how-we-work></app-how-we-work> -->

<app-services></app-services>

<app-why-we-different></app-why-we-different>

<app-work></app-work>

<app-pricing></app-pricing>

<app-faq></app-faq>
<!--
<app-feedback></app-feedback> -->

<app-blog></app-blog>

<!-- <app-partner></app-partner> -->

<app-subscribe></app-subscribe>

<app-contact></app-contact>
