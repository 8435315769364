<div id="services" class="services-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Nuestros <span>Clientes</span></h2>
            <p>Algunos de nuestros clientes.</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/santander.png" alt="services-img">

                    </div>

                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/banregio.png" alt="services-img">

                    </div>

                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/banorte.png" alt="services-img">

                    </div>

                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/euro.png" alt="services-img">

                    </div>

                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/state.png" alt="services-img">

                    </div>

                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/uaem.png" alt="services-img">

                    </div>

                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/cidesi.jpg" alt="services-img">

                    </div>

                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/sm.jpg" alt="services-img">

                    </div>

                </div>
            </div>

        </div>
    </div>
</div>
