<section class="funfacts-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-6 col-sm-3">
                <div class="funfact">
                    <i class="far fa-smile"></i>
                    <h3><span [countUp]="45">00</span></h3>
                    <p>Clientes Satisfechos</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-6 col-sm-3">
                <div class="funfact">
                    <i class="fas fa-shield-alt"></i>
                    <h3><span [countUp]="98">00</span></h3>
                    <p>Proyectos Completados</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-6 col-sm-3">
                <div class="funfact">
                    <i class="fas fa-award"></i>
                    <h3>+ <span [countUp]="250000">00</span></h3>
                    <p>Horas de trabajo</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-6 col-sm-3">
                <div class="funfact">
                    <i class="fas fa-user"></i>
                    <h3> + <span [countUp]="50">00</span></h3>
                    <p>Miembros del Equipo</p>
                </div>
            </div>
        </div>
    </div>
</section>
